import React, { Component } from 'react'
import { Link } from "react-router-dom";
import './menu.css'


function Relatorios() {

    return (
        <li className="nav-item has-treeview menu-open" >

            <Link to="/" className="nav-link">
                <i className="nav-icon fa fa-file-pdf-o" />
                <p>
                    PDF - Documentos
                    <i className="fas fa-angle-left right" />
                    {/* <span className="badge badge-info right">4</span> */}
                </p>
            </Link>
            <ul className="nav nav-treeview">
                {/* <li className="nav-item">
                <Link to="/cotacoes-consulta" className="nav-link">
                    <i className="fa fa-inbox nav-icon" />
                    <p>Novas</p>
                </Link>
            </li> */}
                <li className="nav-item">
                    <Link to="/visualizar-atas" className="nav-link">
                        <i className="fa fa-pencil-square-o nav-icon" />
                        <p>Atas
                        </p>
                    </Link>
                </li>

                <li className="nav-item">
                    <Link to="/visualizar-comunicados" className="nav-link">
                        <i className="fas fa fa-bullhorn nav-icon" />
                        <p>Comunicados
                        </p>
                    </Link>
                </li>

                <li className="nav-item">
                    <Link to="/visualizar-convencao" className="nav-link">
                        <i className="fas fa fa-book nav-icon" />
                        <p>Convenção
                        </p>
                    </Link>
                </li>

                <li className="nav-item">
                    <Link to="/visualizar-editais" className="nav-link">
                        <i className="fa fa-file-text-o nav-icon" />
                        <p>Editais
                        </p>
                    </Link>
                </li>

                <li className="nav-item">
                    <Link to="/visualizar-regulamento-interno" className="nav-link">
                        <i className="fa fa-users nav-icon" />
                        <p>Regulamento Interno
                        </p>
                    </Link>
                </li>
            </ul>

        </li>
    );
}

function RelatorioInad() {

    return (

                <li className="nav-item">
                    <Link to="/visualizar-pendentes" className="nav-link">
                        <i className="fa fa-hourglass-end  nav-icon" />
                        <p>Pendentes
                        </p>
                    </Link>
                </li>

    );
}
function RelatorioFluxo() {

    //if((global.gCond !== '5067') && (global.gCond !== '5011')){
    if(['5067', '5011','5012','5014','5015','5016','5017','5018','5021','5022','5023','5024'].includes(global.gCond) !== true){
        return;
      }

    return (

                <li className="nav-item">
                    <Link to="/visualizar-fluxo" className="nav-link">
                        <i className="fa fa-money  nav-icon" />
                        <p>Fluxo de Caixa
                        <span className="right badge badge-danger">Novo</span>                            
                        </p>
                    </Link>
                </li>

    );
}
function RelatoriosMembro() {

    return (
        <li className="nav-item has-treeview menu-open" >

            <Link to="/" className="nav-link">
                <i className="nav-icon fa fa-file-pdf-o" />
                <p>
                    PDF - Documentos
                    <i className="fas fa-angle-left right" />
                    {/* <span className="badge badge-info right">4</span> */}
                </p>
            </Link>
            <ul className="nav nav-treeview">
                {/* <li className="nav-item">
                <Link to="/cotacoes-consulta" className="nav-link">
                    <i className="fa fa-inbox nav-icon" />
                    <p>Novas</p>
                </Link>
            </li> */}
                <li className="nav-item">
                    <Link to="/visualizar-atas" className="nav-link">
                        <i className="fa fa-pencil-square-o nav-icon" />
                        <p>Atas
                        </p>
                    </Link>
                </li>

                <li className="nav-item">
                    <Link to="/visualizar-comunicados" className="nav-link">
                        <i className="fas fa fa-bullhorn nav-icon" />
                        <p>Comunicados
                        </p>
                    </Link>
                </li>

                <li className="nav-item">
                    <Link to="/visualizar-convencao" className="nav-link">
                        <i className="fas fa fa-book nav-icon" />
                        <p>Convenção
                        </p>
                    </Link>
                </li>

                <li className="nav-item">
                    <Link to="/visualizar-editais" className="nav-link">
                        <i className="fa fa-file-text-o nav-icon" />
                        <p>Editais
                        </p>
                    </Link>
                </li>

                <li className="nav-item">
                    <Link to="/visualizar-regulamento-interno" className="nav-link">
                        <i className="fa fa-users nav-icon" />
                        <p>Regulamento Interno
                        </p>
                    </Link>
                </li>
                <li className="nav-item">
                    <Link to="/visualizar-pasta-digital" className="nav-link">
                        <i className="fa fa-folder nav-icon" />
                        <p>Pasta Digital
                        </p>
                    </Link>
                </li>
            </ul>

        </li>
    );
}

function AssembleiaDig() {

    return (
        <li className="nav-item has-treeview menu-open" >

            <Link to="/" className="nav-link">
                <i className="nav-icon fa fa-handshake-o" />
                <p>
                    Assembleia
                    <i className="fas fa-angle-left right" />
                    {/* <span className="badge badge-info right">4</span> */}
                </p>
            </Link>
            <ul className="nav nav-treeview">
                {/* <li className="nav-item">
                <Link to="/cotacoes-consulta" className="nav-link">
                    <i className="fa fa-inbox nav-icon" />
                    <p>Novas</p>
                </Link>
            </li> */}
                <li className="nav-item">
                    <Link to="/assembleia-digital" className="nav-link">
                        <i className="fa fa-weixin nav-icon" />
                        <p>Assembleia Digital
                        </p>
                    </Link>
                </li>


            </ul>

        </li>
    );
}

function menuGeral() {

    return (
        <li className="nav-item has-treeview menu-open" >

            <Link to="/" className="nav-link">
                <i className="nav-icon fa fa-star" />
                <p>
                    Geral
                    <i className="fas fa-angle-left right" />
                    {/* <span className="badge badge-info right">4</span> */}
                </p>
            </Link>
            <ul className="nav nav-treeview">
                {/* <li className="nav-item">
                <Link to="/cotacoes-consulta" className="nav-link">
                    <i className="fa fa-inbox nav-icon" />
                    <p>Novas</p>
                </Link>
            </li> */}
                <li className="nav-item">
                    <a href="https://api.whatsapp.com/send/?phone=5511972666058&text=Olá,+pode+me+ajudar+?+&type=phone_number&app_absent=0" className="nav-link" target="_blank" rel="noopener noreferrer"  >

                        <i className="nav-icon btn-success fa fa-whatsapp" />

                        <p>WhatsApp</p>


                    </a>
                </li>
                <li className="nav-item">
                    <Link to="/logout" className="nav-link">
                        <i className="nav-icon btn-danger fas fa-close" />
                        <p>Sair</p>
                    </Link>
                </li>

            </ul>

        </li>
    );
}

function MenuMobile(params) {
    return (
        <div id="menumobile" >
            <nav className="z-index-max position-fixed d-flex d-md-none flex-row justify-content-between full-width py-3 px-2 menu-mobile">
                <div style={{ width: '20%' }}>
                    <Link to="/" className="d-flex flex-column align-items-center active" >
                        <i-feather name="monitor" className="icone-menu" _nghost-yyn-c39>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="feather feather-monitor">
                                <rect x={2} y={3} width={2} height={20} rx={2} ry={2} />
                                <rect x={8} y={3} width={1} height={20} rx={2} ry={2} />
                                <rect x={12} y={3} width="0.5" height={20} rx={2} ry={2} />
                                <rect x={16} y={3} width="1.5" height={20} rx={2} ry={2} />
                                <rect x={21} y={3} width={2} height={20} rx={2} ry={2} />
                            </svg>
                        </i-feather>
                        <span className="menubggmobilispan" >BOLETOS</span>
                    </Link>
                </div>
                <div style={{ width: '20%' }}>
                    <Link to="/visualizar-prestacao-de-contas" className="d-flex flex-column align-items-center active" >
                        <i-feather name="monitor" className="icone-menu" _nghost-yyn-c39>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="feather feather-monitor">
                                <rect x={2} y={3} width={20} height={14} rx={2} ry={2} />
                                <line x1={8} y1={21} x2={16} y2={21} />
                                <line x1={12} y1={17} x2={12} y2={21} />
                            </svg>
                        </i-feather>
                        <span className="menubggmobilispan" >CONTAS</span>
                    </Link>
                </div>
                <div style={{ width: '20%' }}>
                    <Link to="/" routerlink="/" className="d-flex flex-column align-items-center">
                        <div className="botao-home">
                            <i-feather name="home" className="icone-menu" _nghost-yyn-c39>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="feather feather-home">
                                    <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z" />
                                    <polyline points="9 22 9 12 15 12 15 22" />
                                </svg>
                            </i-feather>
                        </div>
                        <span className="menubggmobilispan">INÍCIO</span>
                    </Link>
                </div>
                <div style={{ width: '20%' }}>
                    <Link to="/visualizar-atas" routerlink="/visualizar-atas" className="d-flex flex-column align-items-center">
                        <i-feather name="settings-alt" className="icone-menu" _nghost-yyn-c39>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="feather feather-monitor">
                                <rect x={2} y={2} width={20} height={20} rx={3} ry={3} />
                                <line x1={8} y1={8} x2={16} y2={8} />
                                <line x1={8} y1={12} x2={16} y2={12} />
                                <line x1={8} y1={16} x2={16} y2={16} />
                            </svg>
                        </i-feather>
                        <span className="menubggmobilispan">ATAS</span>
                    </Link>
                </div>
                <div style={{ width: '20%' }}>
                    <Link to="/visualizar-comunicados" routerlink="/visualizar-comunicados" className="d-flex flex-column align-items-center">
                        <i-feather name="settings-alt" className="icone-menu" _nghost-yyn-c39>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="feather feather-monitor">
                                <rect x={0} y={8} width={8} height={8} rx={8} ry={8} />
                                <line x1={8} y1={8} x2={20} y2={2} />
                                <line x1={8} y1={16} x2={20} y2={20} />
                                <line x1={20} y1={4} x2={20} y2={18} />
                            </svg>
                            {/*                             <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="feather feather-settings">
                                <path d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z" />
                                <circle id="circle-settings" cx={12} cy={12} r={3} />
                            </svg> */}
                        </i-feather>
                        <span className="menubggmobilispan">COMUNICADOS</span>
                    </Link>
                </div>
            </nav>



        </div>
    )
}

class Menu extends Component {
    componentDidMount() {
        //window.addEventListener('load', this.handleSignOut);

        // const TOKEN_KEY = "KEY_APP_BGG";
        // const token = localStorage.getItem(TOKEN_KEY);
        // var base64Url = token.split('.')[1];
        // var decodedValue = JSON.parse(window.atob(base64Url));
        // console.log(decodedValue);
        // var novoObj = decodedValue;
        // let nome = novoObj.nome;
        // console.log(nome);

        // let buff = new Buffer(token, 'base64'); 
        // if (typeof Buffer.from === "function") {
        //     // Node 5.10+
        //     buff = Buffer.from(buff, 'base64'); // Ta-da
        // } else {
        //     // older Node versions, now deprecated
        //     buff = new Buffer(buff, 'base64'); // Ta-da
        // } 
        // let text = buff.toString('ascii');

    }
    render() {
        //const TOKEN_KEY = "KEY_APP_BGG";
        //const token = localStorage.getItem(TOKEN_KEY);
        //var base64Url = token.split('.')[1];
        //var decodedValue = JSON.parse(window.atob(base64Url));
        //console.log(decodedValue);
        //var novoObj = decodedValue;
        //let nome = novoObj.nome;
        //console.log(nome);
        let itemmenu1;
        let itemmenu2;
        let itemmenu3;
        let itemmenu4;
        let itemmenuInad;
        let itemmenuFluxo;
        let menumob;

        //novo menu 
        //menumob = MenuMobile();

        //210824 liberar pasta digital 5031

        if (global.gNome !== '') {
            itemmenu1 = <li className="nav-item"><Link to="/visualizar-prestacao-de-contas" className="nav-link"><i className="fa fa-bar-chart nav-icon" /><p>Prestação de Contas</p></Link></li>;
            itemmenu2 = AssembleiaDig();
            itemmenu4 = menuGeral();
            if (global.gCargo < 1) {
                itemmenu3 = Relatorios();
            } else {
                itemmenu3 = RelatoriosMembro();
                if (global.gCargo === '1') {
                    itemmenuInad = RelatorioInad();
                    itemmenuFluxo = RelatorioFluxo();
                    //console.log('entrou');
                }
            }
        } else {
            itemmenu1 = '';
            itemmenu2 = '';
            itemmenu3 = '';
            itemmenuInad = '';
            itemmenuFluxo = '';
        }



        return (
            <div >
                {menumob}
                <aside className="main-sidebar sidebar-dark-primary elevation-4" id="menulateral1"  >
                    {/* Brand Logo */}
                    <Link to="/" className="brand-link">
                        <img src="dist/img/logoSupRiluk.png" alt="AdminLTE Logo" className="brand-image img-circle elevation-3" style={{ opacity: '.8' }} />
                        <span className="brand-text font-weight-light">RILUK - App</span>
                    </Link>
                    {/* Sidebar */}


                    <div className="sidebar">
                        <div className="divMenuBgg" style={{ backgroundImage: 'url("/images/bg-01.jpg")' }}></div>


                        {/* Sidebar user panel (optional) */}
                        {/* <div className="user-panel mt-3 pb-3 mb-3 d-flex">
                            <div className="image">
                                <img src="dist/img/user2-160x160.jpg" className="img-circle elevation-2" alt="User" />
                            </div>
                            <div className="info">
                                <Link to="/" className="d-block">{nome}</Link>
                            </div>
                            {/* <form className="form-inline ml-3">
                                <div className=" input-group input-group-sm">
                                    <input className="form-control form-control-navbar" type="search" placeholder="Pesquisar" aria-label="Search" />
                                    <div className="input-group-append">
                                        <button className="btn btn-navbar" type="submit">
                                            <i className="fas fa-search" />
                                        </button>
                                    </div>
                                </div>
                            </form> 
                        </div> */}

                        {/* Sidebar Menu */}
                        <nav className="mt-2" >
                            <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
                                {/* Add icons to the links using the .nav-icon class
         with font-awesome or any other icon font library */}
                                { /*       <li className="nav-item has-treeview menu-close">
                                    <a href="fake_url" className="nav-link active">
                                        <i className="nav-icon fas fa-tachometer-alt" />
                                        <p>
                                            Dashboard
                                            <i className="right fas fa-angle-left" />
                                        </p>
                                    </a>
                                    <ul className="nav nav-treeview">
                                        <li className="nav-item">
                                            <a href="./index.html" className="nav-link active">
                                                <i className="far fa-circle nav-icon" />
                                                <p>Dashboard v1</p>
                                            </a>
                                        </li>
                                        <li className="nav-item">
                                            <a href="./index2.html" className="nav-link">
                                                <i className="far fa-circle nav-icon" />
                                                <p>Dashboard v2</p>
                                            </a>
                                        </li>
                                        <li className="nav-item">
                                            <a href="./index3.html" className="nav-link">
                                                <i className="far fa-circle nav-icon" />
                                                <p>Dashboard v3</p>
                                            </a>
                                        </li>
                                    </ul>
                                </li>
                        */}
                                <li className="nav-item">
                                    <Link to="/" className="nav-link">
                                        <i className="nav-icon fas fa-th" />
                                        <p>
                                            Painel Inicial
                                        </p>
                                    </Link>
                                </li>
                                {itemmenu2}
                                <li className="nav-item has-treeview menu-open" >

                                    <Link to="/" className="nav-link">
                                        <i className="nav-icon fa fa-line-chart" />
                                        <p>
                                            Finanças
                                            <i className="fas fa-angle-left right" />
                                            {/* <span className="badge badge-info right">4</span> */}
                                        </p>
                                    </Link>
                                    <ul className="nav nav-treeview">
                                        {/* <li className="nav-item">
                                            <Link to="/cotacoes-consulta" className="nav-link">
                                            <i className="fa fa-inbox nav-icon" />
                                            <p>Novas</p>
                                            </Link>
                                        </li> */}
                                        <li className="nav-item">
                                            <Link to="/" className="nav-link">
                                                <i className="fas fa-barcode nav-icon btn-warning" />
                                                <p>2ª via boleto
                                                </p>
                                            </Link>
                                        </li>
                                        {itemmenu1}
                                        {itemmenuInad}
                                        {itemmenuFluxo}

                                    </ul>
                                </li>

                                {itemmenu3}

                                {itemmenu4}
                                {/* <li className="nav-item has-treeview " >

                                    <Link to="/cotacoes-gerenciar" className="nav-link">
                                        <i className="nav-icon fas fa-copy" />
                                        <p>
                                            Configurações
                                        <i className="fas fa-angle-left right" />
                                            {/* <span className="badge badge-info right">4</span> */}
                                {/* </p>
                                    </Link>
                                    <ul className="nav nav-treeview"> */}
                                {/* <li className="nav-item">
                                            <Link to="/cotacoes-consulta" className="nav-link">
                                                <i className="fa fa-inbox nav-icon" />
                                                <p>Novas</p>
                                            </Link>
                                        </li> */}
                                {/* <li className="nav-item">
                                            <Link to="/cotacoes-gerenciar" className="nav-link">
                                                <i className="fa fa-cogs nav-icon" />
                                                <p>Contratos
                                                <span className="right badge badge-danger">Desenv</span>
                                                </p>
                                            </Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link to="/cotacoes-gerenciar" className="nav-link">
                                                <i className="fa fa-cogs nav-icon" />
                                                <p>Propostas
                                                <span className="right badge badge-danger">Desenv</span>
                                                </p>
                                            </Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link to="/cotacoes-gerenciar" className="nav-link">
                                                <i className="fa fa-cogs nav-icon" />
                                                <p>Valores Padrões
                                                <span className="right badge badge-danger">Desenv</span>
                                                </p>
                                            </Link>
                                        </li>
                                    </ul>
                                </li> */}
                                {/*
                                <li className="nav-item has-treeview">
                                    <a href="fake_url" className="nav-link">
                                        <i className="nav-icon fas fa-chart-pie" />
                                        <p>
                                            Charts
              <i className="right fas fa-angle-left" />
                                        </p>
                                    </a>
                                    <ul className="nav nav-treeview">
                                        <li className="nav-item">
                                            <a href="pages/charts/chartjs.html" className="nav-link">
                                                <i className="far fa-circle nav-icon" />
                                                <p>ChartJS</p>
                                            </a>
                                        </li>
                                        <li className="nav-item">
                                            <a href="pages/charts/flot.html" className="nav-link">
                                                <i className="far fa-circle nav-icon" />
                                                <p>Flot</p>
                                            </a>
                                        </li>
                                        <li className="nav-item">
                                            <a href="pages/charts/inline.html" className="nav-link">
                                                <i className="far fa-circle nav-icon" />
                                                <p>Inline</p>
                                            </a>
                                        </li>
                                    </ul>
                                </li>
                                <li className="nav-item has-treeview">
                                    <a href="fake_url" className="nav-link">
                                        <i className="nav-icon fas fa-tree" />
                                        <p>
                                            UI Elements
              <i className="fas fa-angle-left right" />
                                        </p>
                                    </a>
                                    <ul className="nav nav-treeview">
                                        <li className="nav-item">
                                            <a href="pages/UI/general.html" className="nav-link">
                                                <i className="far fa-circle nav-icon" />
                                                <p>General</p>
                                            </a>
                                        </li>
                                        <li className="nav-item">
                                            <a href="pages/UI/icons.html" className="nav-link">
                                                <i className="far fa-circle nav-icon" />
                                                <p>Icons</p>
                                            </a>
                                        </li>
                                        <li className="nav-item">
                                            <a href="pages/UI/buttons.html" className="nav-link">
                                                <i className="far fa-circle nav-icon" />
                                                <p>Buttons</p>
                                            </a>
                                        </li>
                                        <li className="nav-item">
                                            <a href="pages/UI/sliders.html" className="nav-link">
                                                <i className="far fa-circle nav-icon" />
                                                <p>Sliders</p>
                                            </a>
                                        </li>
                                        <li className="nav-item">
                                            <a href="pages/UI/modals.html" className="nav-link">
                                                <i className="far fa-circle nav-icon" />
                                                <p>Modals &amp; Alerts</p>
                                            </a>
                                        </li>
                                    </ul>
                                </li>
                                <li className="nav-item has-treeview">
                                    <a href="fake_url" className="nav-link">
                                        <i className="nav-icon fas fa-edit" />
                                        <p>
                                            Forms
              <i className="fas fa-angle-left right" />
                                        </p>
                                    </a>
                                    <ul className="nav nav-treeview">
                                        <li className="nav-item">
                                            <a href="pages/forms/general.html" className="nav-link">
                                                <i className="far fa-circle nav-icon" />
                                                <p>General Elements</p>
                                            </a>
                                        </li>
                                        <li className="nav-item">
                                            <a href="pages/forms/advanced.html" className="nav-link">
                                                <i className="far fa-circle nav-icon" />
                                                <p>Advanced Elements</p>
                                            </a>
                                        </li>
                                        <li className="nav-item">
                                            <a href="pages/forms/editors.html" className="nav-link">
                                                <i className="far fa-circle nav-icon" />
                                                <p>Editors</p>
                                            </a>
                                        </li>
                                    </ul>
                                </li>
                                <li className="nav-item has-treeview">
                                    <a href="fake_url" className="nav-link">
                                        <i className="nav-icon fas fa-table" />
                                        <p>
                                            Tables
              <i className="fas fa-angle-left right" />
                                        </p>
                                    </a>
                                    <ul className="nav nav-treeview">
                                        <li className="nav-item">
                                            <a href="pages/tables/simple.html" className="nav-link">
                                                <i className="far fa-circle nav-icon" />
                                                <p>Simple Tables</p>
                                            </a>
                                        </li>
                                        <li className="nav-item">
                                            <a href="pages/tables/data.html" className="nav-link">
                                                <i className="far fa-circle nav-icon" />
                                                <p>Data Tables</p>
                                            </a>
                                        </li>
                                    </ul>
                                </li>
                                <li className="nav-header">EXAMPLES</li>
                                <li className="nav-item">
                                    <a href="pages/calendar.html" className="nav-link">
                                        <i className="nav-icon far fa-calendar-alt" />
                                        <p>
                                            Calendar
              <span className="badge badge-info right">2</span>
                                        </p>
                                    </a>
                                </li>
                                <li className="nav-item has-treeview">
                                    <a href="fake_url" className="nav-link">
                                        <i className="nav-icon far fa-envelope" />
                                        <p>
                                            Mailbox
              <i className="fas fa-angle-left right" />
                                        </p>
                                    </a>
                                    <ul className="nav nav-treeview">
                                        <li className="nav-item">
                                            <a href="pages/mailbox/mailbox.html" className="nav-link">
                                                <i className="far fa-circle nav-icon" />
                                                <p>Inbox</p>
                                            </a>
                                        </li>
                                        <li className="nav-item">
                                            <a href="pages/mailbox/compose.html" className="nav-link">
                                                <i className="far fa-circle nav-icon" />
                                                <p>Compose</p>
                                            </a>
                                        </li>
                                        <li className="nav-item">
                                            <a href="pages/mailbox/read-mail.html" className="nav-link">
                                                <i className="far fa-circle nav-icon" />
                                                <p>Read</p>
                                            </a>
                                        </li>
                                    </ul>
                                </li>
                                <li className="nav-item has-treeview">
                                    <a href="fake_url" className="nav-link">
                                        <i className="nav-icon fas fa-book" />
                                        <p>
                                            Pages
              <i className="fas fa-angle-left right" />
                                        </p>
                                    </a>
                                    <ul className="nav nav-treeview">
                                        <li className="nav-item">
                                            <a href="pages/examples/invoice.html" className="nav-link">
                                                <i className="far fa-circle nav-icon" />
                                                <p>Invoice</p>
                                            </a>
                                        </li>
                                        <li className="nav-item">
                                            <a href="pages/examples/profile.html" className="nav-link">
                                                <i className="far fa-circle nav-icon" />
                                                <p>Profile</p>
                                            </a>
                                        </li>
                                        <li className="nav-item">
                                            <a href="pages/examples/login.html" className="nav-link">
                                                <i className="far fa-circle nav-icon" />
                                                <p>Login</p>
                                            </a>
                                        </li>
                                        <li className="nav-item">
                                            <a href="pages/examples/register.html" className="nav-link">
                                                <i className="far fa-circle nav-icon" />
                                                <p>Register</p>
                                            </a>
                                        </li>
                                        <li className="nav-item">
                                            <a href="pages/examples/lockscreen.html" className="nav-link">
                                                <i className="far fa-circle nav-icon" />
                                                <p>Lockscreen</p>
                                            </a>
                                        </li>
                                    </ul>
                                </li>
                                <li className="nav-item has-treeview">
                                    <a href="fake_url" className="nav-link">
                                        <i className="nav-icon far fa-plus-square" />
                                        <p>
                                            Extras
              <i className="fas fa-angle-left right" />
                                        </p>
                                    </a>
                                    <ul className="nav nav-treeview">
                                        <li className="nav-item">
                                            <a href="pages/examples/404.html" className="nav-link">
                                                <i className="far fa-circle nav-icon" />
                                                <p>Error 404</p>
                                            </a>
                                        </li>
                                        <li className="nav-item">
                                            <a href="pages/examples/500.html" className="nav-link">
                                                <i className="far fa-circle nav-icon" />
                                                <p>Error 500</p>
                                            </a>
                                        </li>
                                        <li className="nav-item">
                                            <a href="pages/examples/blank.html" className="nav-link">
                                                <i className="far fa-circle nav-icon" />
                                                <p>Blank Page</p>
                                            </a>
                                        </li>
                                        <li className="nav-item">
                                            <a href="starter.html" className="nav-link">
                                                <i className="far fa-circle nav-icon" />
                                                <p>Starter Page</p>
                                            </a>
                                        </li>
                                        <li className="nav-item">
                                            <a href="pages/examples/legacy-user-menu.html" className="nav-link">
                                                <i className="far fa-circle nav-icon" />
                                                <p>Legacy User Menu</p>
                                            </a>
                                        </li>
                                    </ul>
                                </li>
                                <li className="nav-header">MISCELLANEOUS</li>
                                <li className="nav-item">
                                    <a href="https://adminlte.io/docs" className="nav-link">
                                        <i className="nav-icon fas fa-file" />
                                        <p>Documentation</p>
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a href="/logout" className="nav-link">
                                        <i className="nav-icon fas fa-close" />
                                        <p>Sair</p>
                                    </a>
                                </li>
                                <li className="nav-header">LABELS</li>
                                <li className="nav-item">
                                    <a href="fake_url" className="nav-link">
                                        <i className="nav-icon far fa-circle text-danger" />
                                        <p className="text">Important</p>
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a href="fake_url" className="nav-link">
                                        <i className="nav-icon far fa-circle text-warning" />
                                        <p>Warning</p>
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a href="fake_url" className="nav-link">
                                        <i className="nav-icon far fa-circle text-info" />
                                        <p>Informational</p>
                                    </a>
                                </li>
                                */}
                                <li className="nav-header"></li>
                                <li className="nav-item">
                                    {/* <Link to="/logout" className="nav-link">
                                        <i className="nav-icon fas fa-close" />
                                        <p>Sair</p>
                                    </Link> */}
                                </li>
                                <li className="nav-header"></li>
                                <li className="nav-item">
                                    {/* <Link to="/logout" className="nav-link">
                                        <i className="nav-icon fas fa-close" />
                                        <p>Sair</p>
                                    </Link> */}
                                </li>
                            </ul>
                        </nav>
                        {/* /.sidebar-menu */}
                    </div>
                    {/* /.sidebar */}
                </aside>


            </div>
        )
    }
}
export default Menu